import React, { createContext, useEffect } from "react";
import {
  NavigationContainer,
  LinkingOptions,
  ParamListBase,
} from "@react-navigation/native";
import RootNavigation from "./src/";
import { SecureContextProvider } from "./src/context";
import Constants from "expo-constants";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./src/query";
import { Provider } from "react-native-paper";
import useApp from "./src/utils/useApp";
import { SettingsContextProps } from "./src/utils/types";
import { NotificationsContextProvider } from "./src/NotificationsContext";
const LINKING: LinkingOptions<ParamListBase> = {
  prefixes: ["gwc://", Constants.expoConfig!.extra!.AUTH0_BASE_URL],
  config: {
    screens: {
      "Gartner Winners Circle": "",
    },
  },
};
export const SettingsContext = createContext<SettingsContextProps>({
  isFahrenheit: true,
  handleIsFahrenheit: () => {},
});

export default function App() {
  const { theme, fontLoaded, isFahrenheit, handleIsFahrenheit } = useApp();
  return fontLoaded ? (
    <SettingsContext.Provider value={{ isFahrenheit, handleIsFahrenheit }}>
      <Provider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <NavigationContainer linking={LINKING}>
            <SecureContextProvider>
              <NotificationsContextProvider>
                <RootNavigation />
              </NotificationsContextProvider>
            </SecureContextProvider>
          </NavigationContainer>
        </QueryClientProvider>
      </Provider>
    </SettingsContext.Provider>
  ) : null;
}
