import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ClosedSite from "./ClosedSite";

const Stack = createStackNavigator();

const RootNavigation = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Gartner Winners Circle"
        component={ClosedSite}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default React.memo(RootNavigation);
