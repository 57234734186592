import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Platform,
  Image,
  Pressable,
} from "react-native";
import Constants from "expo-constants";
import * as Linking from "expo-linking";

const KeyboardKey = ({ children }: { children: React.ReactNode }) => (
  <View style={styles.keyContainer}>
    <Text style={styles.keyText}>{children}</Text>
  </View>
);

const RefreshButton = ({ onPress }: { onPress: () => void }) => (
  <Pressable
    onPress={onPress}
    style={({ pressed }) => [
      styles.refreshButton,
      pressed && styles.refreshButtonPressed,
    ]}
  >
    <Text style={styles.refreshButtonText}>Gartner Winners Circle</Text>
  </Pressable>
);

const ClosedSite = ({
  imageUrl = require("../../assets/gwc23_spectrum_logo-colour-no_date.png"),
  url = Constants.expoConfig?.extra?.URL_TARGET,
}) => {
  const isMac =
    Platform.OS === "ios" ||
    Platform.OS === "macos" ||
    (Platform.OS === "web" && /Mac/i.test(navigator.userAgent));

  const handleRefresh = () => {
    Linking.openURL(url);
  };

  const refreshKeys = isMac ? (
    <View style={styles.keyboardRow}>
      <KeyboardKey>⌘</KeyboardKey>
      <Text style={[styles.text, styles.keySpacing]}>+</Text>
      <KeyboardKey>R</KeyboardKey>
    </View>
  ) : (
    <View style={styles.keyboardRow}>
      <KeyboardKey>Ctrl</KeyboardKey>
      <Text style={[styles.text, styles.keySpacing]}>+</Text>
      <KeyboardKey>F5</KeyboardKey>
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Image source={imageUrl} style={styles.image} resizeMode="contain" />

        <View style={styles.contentContainer}>
          <Text style={styles.title}>GARTNER WINNERS CIRCLE 2024</Text>
          <Text style={styles.subtitle}>
          COMING SOON!
          </Text>

          {/* <View style={styles.divider} /> */}

          <View style={styles.refreshSection}>
            {/*<View style={styles.refreshContainer}>
              <Text style={styles.text}>Press</Text>
              {refreshKeys}
              <Text style={styles.text}>to refresh</Text>
            </View>*/}
            <Text style={styles.orText}></Text>
            <RefreshButton onPress={handleRefresh} />
          </View>
          {/* <Text style={styles.noteFooter}>
            NOTE: If you experience any difficulty refreshing this page please
            close your browser and try again.
          </Text> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
  },
  card: {
    backgroundColor: "white",
    fontFamily: "coreSansRegular",
    borderRadius: 16,
    padding: 32,
    width: "100%",
    maxWidth: 800,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
  },
  image: {
    width: "100%",
    height: 200,
    marginBottom: 32,
  },
  contentContainer: {
    alignItems: "center",
    width: "100%",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#575756",
    textAlign: "center",
    marginBottom: 8,
    fontFamily: "coreSansBold",
  },
  subtitle: {
    fontSize: 20,
    color: "#575756",
    textAlign: "center",
    marginBottom: 0,
    fontFamily: "coreSansRegular",
  },
  divider: {
    height: 1,
    backgroundColor: "#f0f0f0",
    width: "100%",
    maxWidth: 400,
    marginVertical: 24,
    marginBottom: 50,
    marginTop: 50,
  },
  refreshSection: {
    alignItems: "center",
    gap: 16,
  },
  refreshContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 8,
  },
  keyboardRow: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 4,
  },
  keyContainer: {
    backgroundColor: "#f5f5f5",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#e0e0e0",
    padding: 6,
    minWidth: 32,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  keyText: {
    fontSize: 12,
    fontWeight: "600",
    color: "#575756",
  },
  keySpacing: {
    marginHorizontal: 4,
  },
  text: {
    fontSize: 14,
    color: "#575756",
    fontFamily: "coreSansRegular",
  },
  orText: {
    fontSize: 14,
    color: "#575756",
    marginVertical: 8,
  },
  refreshButton: {
    backgroundColor: "rgb(3, 92, 167)",
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  refreshButtonPressed: {
    opacity: 0.9,
    transform: [{ scale: 0.98 }],
  },
  refreshButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "coreSansRegular",
  },
  footer: {
    marginTop: 32,
    fontSize: 12,
    color: "#575756",
    textAlign: "center",
  },
  noteFooter: {
    marginTop: 32,
    fontSize: 10,
    color: "#575756",
    textAlign: "center",
    fontFamily: "coreSansRegular",
  },
});

export default ClosedSite;
